@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg: #282c34;
  --header: #fff;
  --link: #61dafb;
  --text: hsla(0, 0%, 100%, 0.88);
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

body {
  font-optical-sizing: auto;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.top-shadow {
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

#one,
#two,
#three,
#four,
#five {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.image-container {
  height: 18rem;
}

.image-container img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}

@media (min-width: 640px) {
  .navbar {
    height: 6rem;
    padding-top: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .navbar {
    height: 4rem;
    padding-top: 1rem;
  }
}
